<template>
  <h2>BOOK US NOW!</h2>
  <div id="ic6azszm" class="_1Q9if" data-testid="richTextElement">
    <p class="main-text">
      Email us at
      <a href="mailto:velvetmangod@gmail.com?subject=vmg enquiry" target="_self"
        >velvetmangod@gmail.com</a
      >
    </p>
  </div>
  <p class="main-text">Or you can contact us via our social media</p>
  <div>
    <ul aria-label="Social Bar">
      <li>
        <a href="https://www.facebook.com/VelvetManGod/" target="_blank"
          ><img
            alt="Facebook"
            src="https://static.wixstatic.com/media/e316f544f9094143b9eac01f1f19e697.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01/e316f544f9094143b9eac01f1f19e697.webp"
        /></a>
      </li>
      <li>
        <a href="https://instagram.com/VelvetManGod/" target="_blank"
          ><img
            alt="Instagram"
            src="https://static.wixstatic.com/media/8d6893330740455c96d218258a458aa4.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01/8d6893330740455c96d218258a458aa4.webp"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {},
};
</script>

<style lang="scss" scoped>
$pink: rgb(255, 0, 247);

h2 {
  font-size: 3rem;
  line-height: 6rem;
  background-color: $pink;
  color: white;
  border: 2px solid black;
  border-width: 2px 0;
  margin: 2rem 0;

  text-shadow: rgba(0, 0, 0, 0.498039) -1px -1px 0px,
    rgba(0, 0, 0, 0.498039) -1px 1px 0px, rgba(0, 0, 0, 0.498039) 1px 1px 0px,
    rgba(0, 0, 0, 0.498039) 1px -1px 0px;
}
p {
  font-size: 2rem;
  line-height: 4rem;
}

ul {
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin: 0.5rem 1rem;
    img {
      width: 7rem;
    }
  }
}
</style>

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import TheBand from "../views/TheBand.vue";
import Media from "../views/Media.vue";
import Video from "../views/TheSongs.vue";
import News from "../views/News.vue";
import Contact from "../views/Contact.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/the-band",
    name: "The Band",
    component: TheBand,
  },
  {
    path: "/media",
    name: "Media",
    component: Media,
  },
  {
    path: "/the-songs",
    name: "The Songs",
    component: Video,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/book",
    name: "Book",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

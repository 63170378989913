<template>
  <div>
    <h2>
      <span class="booking"
        >Now
        <span style="text-decoration: underline"
          ><router-link to="book" target="_self">booking</router-link></span
        >&nbsp;for 2023</span
      >
    </h2>
  </div>
  <div>
    <img class="banner" src="img/banner.png" alt="banner.png" />
  </div>

  <div class="main-text blurb">
    <p>
      Do you run a pub, bar, restaurant, or club? Then you should book
      <strong>Velvet Man God!</strong>
    </p>
    <p>
      Having a birthday, a wedding, or a funeral? Velvet Man God has a setlist
      for <strong>every occasion!</strong>
    </p>
    <p>
      Big stage or small. Velvet Man God can shrink or expand to
      <strong>fit any space!</strong>
    </p>
    <p>
      Indoors or outdoors, day or night, rain or shine, Velvet Man God continues
      to <strong>ROCK!</strong>
    </p>
    <p>
      Fully equipped with <strong>guitars and drums</strong>, our specially
      trained instrument operators can also provide an optional
      <strong>loudspeaker PA system</strong> and <strong>lighting rig</strong>.
      With <strong>public liability insurance</strong> as standard you can now
      experience Velvet Man God live <strong>anywhere</strong> permissible by
      law.
    </p>
    <p>
      But wait! That's not all. You need Velvet Man God somewhere impermissible
      by law? <router-link to="book" target="_self">Contact us</router-link>
      <strong> now</strong> and one of our friendly team will get back to you.
    </p>
  </div>

  <p class="strap">"60% of the time, we rock you every time!"</p>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped lang="scss">
$pink: rgb(255, 0, 247);

.booking {
  font-family: futura-lt-w01-book, sans-serif;
  font-size: 4.5rem;
}

.banner {
  width: 70rem;
  object-fit: cover;
}

.blurb {
  font-size: 1.6rem;
  text-align: left;
  width: 65rem;
  margin: auto;
}

.strap {
  font-weight: 200;
  font-family: "Midnight Drive", sans-serif;
  font-size: 4rem;
}
</style>

<template>
  <h2>
    Visit our
    <a href="https://www.instagram.com/velvetmangod/">Instagram Profile</a> for
    more
  </h2>
  <div>
    <iframe
      class="youtube"
      src="https://www.youtube.com/embed/UOiAPu7g4c8"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <div>
    <a href="http://instagram.com/VelvetManGod" target="_blank">
      <img class="banner" src="img/gallery.jpg" alt="gallery preview" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Media",
  components: {},
};
</script>

<style scoped lang="scss">
$pink: rgb(255, 0, 247);

.youtube {
  width: 70rem;
  height: 41.1rem;
}
h2 {
  font-size: 4.5rem;
  border-bottom: 0;
  line-height: 1rem;
}

.banner {
  width: 70rem;
  object-fit: cover;
}
</style>

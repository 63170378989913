<template>
  <div>
    <Carosel />

    <div class="main-text main">
      <div class="start">
        <h2>In 1972...</h2>
      </div>

      <div class="main-text story">
        <p>
          a crack musical unit was sent to prison by a military court for a
          crime against pop music they
          <router-link to="media" target="_self">did not commit</router-link>.
          These men promptly escaped from a maximum-security stockade to the
          Chepstow underground.
        </p>

        <p>
          Today, still wanted by the government, they survive as rock stars of
          fortune.
        </p>

        <p>
          If you want to
          <router-link to="the-songs" target="_self">rock</router-link>, if no
          one else can help you, and if you can
          <router-link to="news" target="_self">find them</router-link>, maybe
          <router-link to="book" target="_self">you can hire</router-link>
        </p>
      </div>

      <div class="end">
        <h2>...Velvet Man God.</h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Carosel from "../components/Carosel.vue";

export default {
  name: "The Band",
  components: { Carosel },
};
</script>

<style scoped lang="scss">
$pink: rgb(255, 0, 247);

.main {
  margin-bottom: 2rem;

  h2 {
    font-weight: 200;
    font-family: "Midnight Drive", sans-serif;
    font-size: 4rem;
    margin: auto;
  }

  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 6rem;
    }
  }

  .start {
    margin-right: 50rem;
    margin-bottom: -2rem;
    width: 35rem;
  }

  .end {
    margin-top: -2rem;
    margin-left: 35rem;
    width: 50rem;
  }

  .story {
    width: 60rem;
    margin: auto;
    text-align: left;
    font-size: 2rem;
  }
}
</style>


import { Options, Vue } from "vue-class-component";
import TopBar from "./components/TopBar.vue";
import Title from "./components/Title.vue";
import MainMenu from "./components/MainMenu.vue";
import Footer from "./components/Footer.vue";

var host = window.location.host;
var hasSubdomain = host.split("/")[0] != host;
if (hasSubdomain) window.location.assign("http://velvetmangod.co.uk");

@Options({
  components: {
    TopBar,
    Title,
    MainMenu,
    Footer,
  },
})
export default class App extends Vue {}

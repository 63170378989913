<template>
  <div class="main-text grid">
    <img src="covers/1.jpg" />
    <img src="covers/2.jpg" />
    <img src="covers/3.jpg" />
    <img src="covers/4.jpg" />
    <img src="covers/5.jpg" />
    <img src="covers/6.jpg" />
    <img src="covers/7.jpg" />
    <img src="covers/8.jpg" />
    <img src="covers/9.jpg" />
    <img src="covers/10.jpg" />

    <img src="covers/11.jpg" />
    <img src="covers/12.jpg" />
    <img src="covers/13.jpg" />
    <img src="covers/14.jpg" />
    <img src="covers/15.jpg" />
    <img src="covers/16.jpg" />
    <img src="covers/17.jpg" />
    <img src="covers/18.jpg" />
    <img src="covers/19.jpg" />
    <img src="covers/20.jpg" />

    <img src="covers/21.jpg" />
    <img src="covers/22.jpg" />
    <img src="covers/23.jpg" />
    <img src="covers/24.jpg" />
    <img src="covers/25.jpg" />
    <img src="covers/26.jpg" />
    <img src="covers/27.jpg" />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "The Songs",
  components: {},
};
</script>
<style scoped lang="scss">
span {
  font-size: 2rem;
}
h2 {
  // font-weight: 200;
  // font-family: "Midnight Drive", sans-serif;
  font-size: 6rem;
  margin: auto;
}

.grid {
  width: 60rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 5px 5px;

  img {
    width: 100%;
  }
}
</style>

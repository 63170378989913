<template>
  <div class="about">
    <h2>Under Construction.</h2>
    <p class="main-text">Please keep an eye on our socials for upcoming gigs</p>
    <ul aria-label="Social Bar">
      <li>
        <a href="https://www.facebook.com/VelvetManGod/" target="_blank"
          ><img
            alt="Facebook"
            src="https://static.wixstatic.com/media/e316f544f9094143b9eac01f1f19e697.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01/e316f544f9094143b9eac01f1f19e697.webp"
        /></a>
      </li>
      <li>
        <a href="https://instagram.com/VelvetManGod/" target="_blank"
          ><img
            alt="Instagram"
            src="https://static.wixstatic.com/media/8d6893330740455c96d218258a458aa4.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01/8d6893330740455c96d218258a458aa4.webp"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "The Band",
  components: {},
};
</script>
<style scoped lang="scss">
p {
  font-size: 2rem;
  line-height: 4rem;
}

ul {
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin: 0.5rem 1rem;
    img {
      width: 7rem;
    }
  }
}
</style>
